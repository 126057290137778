import React, {useContext, useEffect, useMemo, useState} from "react";

import BundleFlag from "./BundleFlag";

import {LocalizationContext} from "../common/Locaization";
import ListRow from "./ListRow";
import {AppStateContext} from "../context/appState";
import Bundle from "../screens/bundle";
import {useNavigate} from "react-router-dom";

export default function BundlesList({bundles}) {
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const navigation = useNavigate();


    return ((bundles).map((bundle) => (<ListRow
                onClick={() => {
                    navigation("/bundle/" + bundle.id)
                }}
                image={(<BundleFlag iconName={bundle.iconName}/>)}
                label={localization.GetLocalized(bundle.name)}
                key={bundle.id}/>)));
}



import React from "react";
import {useNavigate} from "react-router-dom";
import {GetSimCard} from "../common/Api";

export default function SimCardRedirect() {
    const navigation = useNavigate();

    if (window.location.hash.startsWith("#simcard")) {
        const simCardId = window.location.hash.replace("#simcard", "");
        GetSimCard(simCardId).then(simCard => {
            if (simCard) {
                navigation("/simcards/" + simCard.iccid, {replace: true});
            }
        });
    }
}

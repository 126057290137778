import React, {useContext} from "react";
import config from "../../config";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {useParams} from "react-router-dom";
import {LocalizationContext} from "../../common/Locaization";

export default function StaticPageView() {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(null);
    const localization = useContext(LocalizationContext);
    const {name} = useParams();

    React.useEffect(() => {
        setLoading(true);
        fetch(`${config.imgUrl}pages/${name}.html`)
            .then(response => response.text())
            .then(data => {
                setData(data);
                setLoading(false);
            });
    }, [name]);
    return (<div className={"container page"}>
        <div className={"content"}>
            {loading ? (<LoadingSpinner/>) : (
                <>
                    <p className={"pagetitle"}>{localization.GetString(name)}</p>
                    <div className={"text-content"} dangerouslySetInnerHTML={{__html: data}} onClick={(e) => {
                        if (e.target?.attributes?.href) {
                            const href = e.target.attributes.href.value;
                            if (href.startsWith("http")) {
                                window.Telegram.WebApp.openLink(href);
                            }
                        }
                        e.preventDefault();
                    }}/>
                </>
            )}
        </div>
    </div>);
}

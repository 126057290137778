import React, {useContext, useEffect} from "react";
import config from "../config";
import {DataStateContext} from "../context/dataState";

export default function MainButton() {
    const [dataState, dispatchDataState] = useContext(DataStateContext);

    useEffect(() => {
        if (!config.telegram || dataState.mainButton == null) {
            return;
        }

        window.Telegram.WebApp.MainButton.text = dataState.mainButton.text;
        window.Telegram.WebApp.MainButton.onClick(dataState.mainButton.onClick);

        return () => {
            window.Telegram.WebApp.MainButton.offClick(dataState.mainButton.onClick);
        }

    }, [dataState.mainButton]);

    useEffect(() => {
        if (!config.telegram) {
            return;
        }
        if (dataState.mainButtonVisible) {
            window.Telegram.WebApp.MainButton.show();
        } else {
            window.Telegram.WebApp.MainButton.hide();
        }
    }, [dataState.mainButtonVisible]);

    if ((!config.dev && config.telegram) || dataState.mainButton == null || !dataState.mainButtonVisible) {
        return null;
    }
    return (
        <button onClick={dataState.mainButton.onClick} className="main-button">{dataState.mainButton.text}</button>
    );
}

import React, {useContext} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {LocalizationContext} from "../common/Locaization";
import searchIcon from "../assets/search.svg";
import resetIcon from "../assets/reset.svg";
import profileIcon from "../assets/profile.svg";
import {AppStateContext} from "../context/appState";
import config from "../config";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";


export default function Header({useSearch, onSearchChange, pageTitle}) {

    const [tonConnectUI] = useTonConnectUI();
    const wallet = useTonWallet();
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const navigation = useNavigate();
    const {searchText} = useParams();

    return (<header>
        <div>
            {pageTitle && (<div
                className={"title"}>{pageTitle}</div>)}
            {useSearch && (<div className={"search"}>
                <div className={"input-wrap"}>
                    <input type={"text"} value={searchText ?? ""}
                           placeholder={localization.GetString("search_placeholder")} onChange={(e) => {
                        if (e.target.value) {
                            navigation("/search/" + e.target.value, {replace: searchText?.length > 0});
                        } else {
                            navigation("/");
                        }
                    }
                    }/>
                    <img className={"search-icon"} src={searchIcon} alt=""/>
                    {searchText && (<img className={"reset-icon"} src={resetIcon} onClick={() => {
                        navigation("/");
                    }}/>)}

                </div>
                <div className={"profile-wrap"} onClick={
                    () => {
                        if (config.tonkeeper && !wallet) {
                            tonConnectUI.openSingleWalletModal("tonkeeper")
                            return;
                        }
                        navigation("/profile");
                    }
                }>
                    <img src={profileIcon}/>
                    <div className={"label"}>
                        {localization.GetString("account")}
                    </div>

                </div>
            </div>)}
        </div>

    </header>);
}

import React, {useContext, useEffect} from "react";
import {AppStateContext} from "../context/appState";
import {useLocation, useNavigate} from "react-router-dom";


export default function BackNavigationController() {
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const location = useLocation();
    const navigate = useNavigate();
    const checkBack = () => {
        if (window.history.state?.idx > 0) {
            window.Telegram.WebApp.BackButton.show();
        } else {
            window.Telegram.WebApp.BackButton.hide();
        }
    }
    const back = () => {
        checkBack();
        window.history.back(0);
    }


    useEffect(() => {
        window.Telegram.WebApp.BackButton.onClick(back);
        return () => {
            window.Telegram.WebApp.BackButton.offClick(back);
        }
    }, []);
    useEffect(() => {
        checkBack();
    }, [location]);
    return null;
}

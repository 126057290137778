import React, {useContext, useMemo} from "react";
import {DataStateContext} from "../context/dataState";
import {LoadingSpinner} from "../components/LoadingSpinner";
import BundlesList from "./BundlesList";
import {LocalizationContext} from "../common/Locaization";

export default function BundlesGroup({type, showPopular, alphabeticalSort = false, global = false}) {
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const localization = useContext(LocalizationContext);
    const bundles = useMemo(() => {
        if (dataState.bundles == null) {
            return [];
        }
        return dataState.bundles.filter(bundle => {

                if (showPopular && !bundle.popularity) {
                    return false
                }
                if (type === "region" && bundle.global !== global) {
                    return false
                }
                return bundle.type == type;
            }
        ).sort((a, b) => alphabeticalSort ? (localization.GetLocalized(a.name).localeCompare(localization.GetLocalized(b.name))) : (b.popularity - a.popularity));
    }, [dataState.bundles]);

    return (
        <>
            {dataState.bundles == null ? (<LoadingSpinner/>) : (<BundlesList bundles={bundles}/>)}
        </>
    );
}

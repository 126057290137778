import React, {useContext} from "react";
import ListRow from "../../elements/ListRow";
import Header from "../../components/Header";
import {DataStateContext} from "../../context/dataState";
import {AppStateContext} from "../../context/appState";
import {LocalizationContext} from "../../common/Locaization";
import profileThemeSystemIcon from "../../assets/profile-theme-system.svg";
import profileThemeLightIcon from "../../assets/profile-theme-light.svg";
import profileThemeDarkIcon from "../../assets/profile-theme-dark.svg";
import {SaveProfile} from "../../common/Api";

export default function Theme() {
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const selectTheme = (theme) => {
        SaveProfile({theme}).then((data) => {
            dispatchDataState({type: 'updateUserData', payload: data});
            dispatchAppState({type: "popNavigationAction"});
        });
    }
    return (<div className={"container page"}>
        <Header pageTitle={localization.GetString("theme")}/>
        <div className={"content"}>
            <ListRow imageUrl={profileThemeLightIcon} label={localization.GetString("theme_light")} onClick={() => {
                selectTheme("light");
            }}/>
            <ListRow imageUrl={profileThemeDarkIcon} label={localization.GetString("theme_dark")} onClick={() => {
                selectTheme("dark");
            }}/>
            <ListRow imageUrl={profileThemeSystemIcon} label={localization.GetString("theme_system")} onClick={() => {
                selectTheme("system");
            }}/>


        </div>
    </div>);
}

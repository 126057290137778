import React, {useContext, useEffect, useMemo, useState} from "react";
import {DataStateContext} from "../context/dataState";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {CreateOrder, GetBundleTariffs} from "../common/Api";
import BundleHeader from "../elements/BundleHeader";
import Header from "../components/Header";
import {LocalizationContext} from "../common/Locaization";
import {AppStateContext} from "../context/appState";
import Bg from "../elements/Bg";
import countriesBg from "../assets/countries.svg";
import regionsBg from "../assets/regions.svg";
import config from "../config";
import {useNavigate, useParams} from "react-router-dom";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";

export default function Bundle() {
    const localization = useContext(LocalizationContext);
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();

    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [tariffs, setTariffs] = useState(null);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const {id} = useParams();
    const navigation = useNavigate();
    const [showCountries, setShowCountries] = useState(false);
    const [bundle, setBundle] = useState(null);

    useEffect(() => {
        setBundle(dataState.bundles?.find(b => b.id == id));
    }, [id, dataState.bundles]);

    const countryNames = useMemo(() => {
        if (bundle?.countries == null || !dataState.countries) {
            return [];
        }

        let ret = dataState.countries.filter(country => bundle.countries.includes(country.code)).map(country => localization.GetLocalized(country.name));
        ret = ret.sort();
        return ret;

    }, [bundle, dataState.countries]);


    const goToCheckout = () => {

        if (config.website) {
            navigation("/pay/web/" + selectedTariff.id);
        } else if (config.tonkeeper) {
            if (!wallet) {
                tonConnectUI.openSingleWalletModal("tonkeeper")

            } else {
                navigation("/pay/ton/" + selectedTariff.id);
            }
        } else {
            navigation("/bundle/" + bundle.id + "/" + selectedTariff.id);
        }

    }

    useEffect(() => {
        if (bundle != null && selectedTariff != null) {

            let buttonText;
            if (config.tonkeeper && !wallet) {
                buttonText = localization.GetString("connect_ton");
            } else {
                buttonText = localization.GetString("go_to_checkout");
            }

            dispatchDataState({
                type: 'setMainButton',
                payload: {text: buttonText, onClick: goToCheckout}
            });
            return () => {
                dispatchDataState({type: 'setMainButton', payload: null});
            }
        }
    }, [bundle, wallet, selectedTariff]);

    useEffect(() => {
        if (!bundle) {
            return;
        }
        GetBundleTariffs(bundle.id).then(data => {
            data = data.sort((a, b) => a.dataSize - b.dataSize);
            const minTariff = data[0];
            setSelectedTariff(minTariff);
            let tariffs = [];

            for (let tariff of data) {
                let tariffDiscount = Math.round((minTariff.price / minTariff.dataSize - tariff.price / tariff.dataSize) / minTariff.price * 100);
                let gbPrice = Math.round(tariff.price / tariff.dataSize * 100) / 100;
                tariffs.push({...tariff, tariffDiscount, gbPrice});
            }

            dispatchDataState({type: 'setTariffs', payload: tariffs});
            setTariffs(tariffs);
            dispatchDataState({type: 'setMainButtonVisible', payload: true})
        });
        return () => {
            dispatchDataState({type: 'setMainButtonVisible', payload: false})

        }

    }, [bundle]);

    return (<div className={"container page"}>
        {!bundle ? (<LoadingSpinner/>) : (<div className={"content "}>
            {dataState.bundles == null ? (<LoadingSpinner/>) : (<>
                <BundleHeader bundle={bundle} vertical={true}/>
                <div className={"pagesubtitle"}>{localization.GetString("choose_a_data_plan")}</div>
                {tariffs == null ? (<LoadingSpinner/>) : (<ul className={"tariffs-list"}>
                    {tariffs.map((tariff) => (
                        <li key={tariff.id} className={tariff.id == selectedTariff.id ? "selected" : ""}
                            onClick={() => {
                                setSelectedTariff(tariff);
                            }}
                        >
                            <div className={"sizes"}>
                                <div className={"label"}>{tariff.dataSize} {localization.GetString("gb")}</div>
                                <div className={"value"}>{tariff.days} {localization.GetString("days")}</div>

                            </div>
                            <div>
                                <div className={"label"}>{tariff.price} $</div>
                                <div className={"value"}>$ {tariff.gbPrice} / {localization.GetString("gb")} </div>
                            </div>
                            <div className={"checkwrap"}>
                                {tariff.tariffDiscount > 0 && (<div className={"discount"}>
                                    -{tariff.tariffDiscount}%
                                </div>)}
                                <div className={"check"}></div>
                            </div>
                        </li>))}
                </ul>)}
                {bundle.countries.length > 1 && (<>
                    <div className={"countries-button "} onClick={() => {
                        setShowCountries(!showCountries);
                    }}>
                        {localization.GetString("available_countries")} {bundle.countries.length}
                    </div>
                    <ul className={"bundle-countries " + (showCountries ? "opened" : "")}>
                        {countryNames.map((country, idx) => (<li key={idx}>{country}</li>))}
                    </ul>
                </>)}
            </>)}
        </div>)}
    </div>);
}

import React, {useContext, useEffect, useState} from "react";
import {QRCode} from "react-qrcode-logo";
import {LocalizationContext} from "../../common/Locaization";
import Header from "../../components/Header";
import BundleHeader from "../../elements/BundleHeader";
import {StringDate} from "../../common/DateHelper";
import {useParams} from "react-router-dom";
import {GetSimCard} from "../../common/Api";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import qrLogo from "../../assets/logo_mobile_white.png";

export default function SimCardInfo() {
    const localization = useContext(LocalizationContext);
    const {id} = useParams();
    const [simCard, setSimCard] = useState(null);

    useEffect(() => {
        GetSimCard(id).then(data => {
            setSimCard(data);
        });
    }, [id]);

    return (<div className={"container page"}>
        {!simCard ? (<LoadingSpinner/>) : simCard.activated ?
            simCard.dataPackages.map((bundle) => (<div className={"content"}>
                    <BundleHeader bundle={bundle}/>
                    <div className={"data-item-details"}>
                        <div className={"row"}>
                            <div className={"label"}>ICCID</div>
                            <div className={"value"}>
                                {simCard.iccid}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"label"}>{localization.GetString("my_esims_date")}</div>
                            <div
                                className={"value"}>{StringDate(bundle.createdAt)}</div>

                        </div>

                        <div className={"row"}>
                            <div className={"label"}>{localization.GetString("my_esims_activation_date")}</div>
                            <div
                                className={"value"}>{StringDate(bundle.activatedAt)}</div>

                        </div>
                        <div className={"row"}>
                            <div className={"label"}>{localization.GetString("my_esims_expiration_date")}</div>
                            <div
                                className={"value"}>{StringDate(bundle.expireAt)}</div>

                        </div>

                        {(bundle.status !== "deactivated") && (
                            <div className={"row"}>
                                <div className={"label"}>{localization.GetString("my_esims_remaining_days")}</div>
                                <div
                                    className={"value"}>{bundle.remainingDays ? bundle.remainingDays : localization.GetString("data_loading")}</div>

                            </div>)}
                        {(bundle.status !== "deactivated") && (
                            <div className={"row"}>
                                <div className={"label"}>{localization.GetString("my_esims_remaining_data")}</div>
                                <div
                                    className={"value"}>{bundle.remainingData ? bundle.remainingData + localization.GetString("gb") : localization.GetString("data_loading")}</div>

                            </div>)}
                    </div>
                </div>)
            ) : (<div className={"text-page"}>

                <div className={"qr-info"}>
                    <div className={"wrapper"}>
                        <div className={"qr-inner"}>
                            <QRCode size={180} value={simCard.qrCode} fgColor={"#0096F9"} logoImage={qrLogo}
                                    logoHeight={39}
                                    logoPadding={1} logoWidth={39}/>
                        </div>
                    </div>
                </div>
                <p className={"title"}>{localization.GetString("how-to-install")}</p>
                <ol>
                    <li>
                        {localization.GetString("how-to-install-step1")}
                    </li>
                    <li>
                        {localization.GetString("how-to-install-step2")}
                    </li>
                    <li>
                        {localization.GetString("how-to-install-step3")}
                        <p>
                            <b>SM-DP:</b><br/>{simCard.smDp}
                        </p>
                        <p>
                            <b>Activation code:</b><br/>{simCard.activationCode}
                        </p>
                        {simCard.confirmationCode && (<p>
                                <b>Confirmation code:</b><br/>{simCard.confirmationCode}
                            </p>
                        )}
                    </li>
                </ol>
            </div>)}
    </div>)


}

import React, {useContext} from "react";
import ListRow from "../../elements/ListRow";
import Header from "../../components/Header";
import {LocalizationContext} from "../../common/Locaization";

import profileEsimIcon from "../../assets/profile-esims.svg";
import profileOrdersIcon from "../../assets/profile-orders.svg";

import profileLanguageIcon from "../../assets/profile-language.svg";

import profileThemeIcon from "../../assets/profile-theme.svg";
import {AppStateContext} from "../../context/appState";
import SimCards from "./simcards";
import Language from "./language";
import Theme from "./theme";
import {DataStateContext} from "../../context/dataState";
import Orders from "./orders";
import StaticPageView from "./StaticPageView";
import {useNavigate} from "react-router-dom";
import config from "../../config";

export default function Profile() {
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const navigation = useNavigate();
    return (<div className={"container page"}>
        <div className={"content"}>
            <div className={"pagetitle"}>{localization.GetString("account")}</div>
            <div className={"list-tile"}>{localization.GetString("profile")}</div>
            <ListRow imageUrl={profileEsimIcon} label={localization.GetString("my_esims")} onClick={() => {
                navigation("/simcards")
            }}/>
            <ListRow imageUrl={profileOrdersIcon} label={localization.GetString("orders")} onClick={
                () => {
                    navigation("/orders")
                }
            }/>
            {!config.website && (<>
                <div className={"list-tile"}>{localization.GetString("settings")}</div>
                <ListRow imageUrl={profileLanguageIcon}
                         label={localization.GetString("language")}
                         value={localization.GetString("language_" + dataState.userData.language)}
                         onClick={() => {
                             navigation("/language")
                         }}/>
                <ListRow imageUrl={profileThemeIcon} label={localization.GetString("theme")}
                         value={localization.GetString("theme_" + dataState.userData.theme)} onClick={() => {
                    navigation("/theme")
                }}/>
                <div className={"list-tile"}>{localization.GetString("profile_other")}</div>
                <ListRow label={localization.GetString("privacy_notes")} onClick={() => {
                    navigation("/info/privacy-notes");
                }}/>
                <ListRow label={localization.GetString("complaints_policy")} onClick={() => {
                    navigation("/info/complaints-policy");
                }}/>
                <ListRow label={localization.GetString("terms_and_conditions")} onClick={() => {
                    navigation("/info/terms-and-conditions");
                }}/>
                <ListRow label={localization.GetString("contact_us")} onClick={() => {
                    window.Telegram.WebApp.openTelegramLink("https://t.me/MobileSuppBot");
                }}/>
            </>)}
        </div>

    </div>);
}

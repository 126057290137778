import React, {useContext} from "react";
import {useParams} from "react-router-dom";
import {LocalizationContext} from "../common/Locaization";

export default function PaymentResult() {
    const {status} = useParams();
    const localization = useContext(LocalizationContext);

    return (
        <div className={"container page"}>
            <div className={"info-page"}>
                {status === "method-failed" ? (<>
                    <h2>{localization.GetString("payment-method-failed")}</h2>
                    <p>{localization.GetString("payment-method-failed-text")}</p>
                </>) : status === "payment-failed" ? (<>
                    <h2>{localization.GetString("payment-failed")}</h2>
                    <p>{localization.GetString("payment-failed-text")}</p>
                </>) : (<>
                    <h2>{localization.GetString("payment-unknown-status")}</h2>
                    <p>{localization.GetString("payment-unknown-text")}</p>
                </>)}
            </div>
        </div>
    )
}

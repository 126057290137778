import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Bundles from "../screens/bundles";
import Bundle from "../screens/bundle";
import BundleCheckout from "../screens/bundleCheckout";
import Pay from "../screens/pay";
import SimCardInfo from "../screens/profile/SimCardInfo";
import Profile from "../screens/profile/profile";
import Language from "../screens/profile/language";
import Theme from "../screens/profile/theme";
import SimCards from "../screens/profile/simcards";
import Orders from "../screens/profile/orders";
import StaticPageView from "../screens/profile/StaticPageView";
import SimCardRedirect from "./SimCardRedirect";
import config from "../config";
import BackNavigationController from "./BackNavigationController";
import MainButton from "../elements/MainButton";
import PaymentResult from "../screens/paymentResult";


export default function AppNavigation() {
    return (<BrowserRouter basename={config.baseName}>
        {config.telegram && (<BackNavigationController/>)}
        <SimCardRedirect/>
        <Routes>
            <Route path={"/"} element={<Bundles/>}/>
            <Route path={"/regions"} element={<Bundles mode={"region"}/>}/>
            <Route path={"/global"} element={<Bundles mode={"global"}/>}/>
            <Route path={"/search/:searchText"} element={<Bundles mode={"search"}/>}/>
            <Route path={"/bundle/:id"} element={<Bundle/>}/>
            <Route path={"/bundle/:id/:tariffId"} element={<BundleCheckout/>}/>
            <Route path={"/pay/:tariffId/:paymentMethodId"} element={<Pay/>}/>
            <Route path={"/pay/web/:tariffId"} element={<Pay web={true}/>}/>
            <Route path={"/pay/ton/:tariffId"} element={<Pay ton={true}/>}/>
            <Route path={"/pay/result/:status"} element={<PaymentResult/>}/>
            <Route path={"/profile"} element={<Profile/>}/>
            <Route path={"/language"} element={<Language/>}/>
            <Route path={"/theme"} element={<Theme/>}/>
            <Route path={"/simcards"} element={<SimCards/>}/>
            <Route path={"/simcards/:id"} element={<SimCardInfo/>}/>
            <Route path={"/orders"} element={<Orders/>}/>
            <Route path={"/info/:name"} element={<StaticPageView/>}/>
        </Routes>
        <MainButton/>
    </BrowserRouter>);
}

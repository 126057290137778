import config from "../config";

const registry_url = '/api/esim/';

function Call(method, data = null) {

    const headers = {
        'Content-Type': 'application/json',
    }

    if (config.telegram) {
        if (config.dev) {
            headers.InitData = 'query_id=AAHTBhgCAAAAANMGGALSeuVH&user=%7B%22id%22%3A35129043%2C%22first_name%22%3A%22Alexander2222%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22Alexander2222%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1711894286&hash=42c2bcb1ce015ca6b25781db70b3262e8d15137d2d1b6defba74affac0cdb245';
        } else {
            headers.InitData = window.Telegram.WebApp.initData;

        }
    }

    return fetch(registry_url + method, {
        method: data == null ? 'GET' : 'POST',
        headers,
        body: data == null ? null : JSON.stringify(data)
    });
}

export async function GetBundles() {
    return Call('bundles').then(response => response.json());
}

export async function GetCountries() {
    return Call('countries').then(response => response.json());
}

export async function GetBundleTariffs(id) {
    return Call('tariffs?bundleId=' + id).then(response => response.json());
}

export async function GetTariff(id) {
    return Call('tariff?id=' + id).then(response => response.json());
}

export async function CreateOrder(tariffId, paymentMethodId, web = false, ton = false) {
    return Call('orders',
        {
            tariffId: tariffId,
            paymentMethodId: paymentMethodId,
            web: web,
            ton: ton
        }
    ).then((response) => {
        return response.json();
    });
}


export async function GetOrder(id) {
    return Call('orders/' + id).then(response => response.json());
}

export async function GetOrders() {
    return Call('orders').then(response => response.json());
}


export async function OrderStatus(id) {
    return Call('orders/' + id + '/status').then(response => response.json());
}

export async function GetSimCard(id) {
    return Call('simcards/' + id).then(response => response.json());
}

export async function GetPaymentMethods() {
    return Call('payment-methods').then(response => response.json());
}

export async function GetUserData() {
    return Call('user').then(response => response.json());
}

export async function GetWebUserData() {
    return Call('user/webauth', {}).then(response => response.json());
}

export async function TonAuth(walletStateInit, address, walletAddress) {
    return Call('user/tonauth', {
        WalletStateInit: walletStateInit,
        Address: address,
        WalletAddress: walletAddress,
        Locale: navigator.language
    }).then(response => response.json());
}

export async function CreatePayment(orderId, method) {
    return Call('pay',
        {orderId: orderId, paymentMethodId: method}
    ).then((response) => {
        return response.json();
    });
}

export async function GetNotificationToken() {
    return Call('notification-token').then(response => response.json());
}

export async function GetSimCards() {
    return Call('simcards').then(response => response.json());
}


export async function SaveProfile({language, theme}) {
    return Call('user/profile', {language, theme}).then(response => response.json());
}

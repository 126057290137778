import React, {useContext, useEffect, useMemo, useState} from "react";
import Payment from "../elements/Payment";
import {LocalizationContext} from "../common/Locaization";
import ProgressLogo from "../assets/progress-logo.svg";
import {CreateOrder, GetOrder, OrderStatus} from "../common/Api";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTonConnectUI} from "@tonconnect/ui-react";
import {beginCell, toNano} from '@ton/ton'


export default function Pay({web = false, ton = false}) {
    const [orderStatus, setOrderStatus] = useState("created");
    const [paymentData, setPaymentData] = useState(null); // payment
    const [order, setOrder] = useState(null);
    const localization = useContext(LocalizationContext);
    const {tariffId, paymentMethodId} = useParams();
    const navigation = useNavigate();
    const startHistoryKey = useMemo(() => {
        return window.history.state.key;
    }, []);

    const [tonConnectUI] = useTonConnectUI();

    const resetNavigation = async function () {
        return new Promise(async (resolve, reject) => {
            console.log("Start going back")
            while (true) {
                window.history.back();
                await new Promise(r => setTimeout(r, 100));
                if (window.history.state?.key != startHistoryKey) {
                    console.log("Finish going back")
                    resolve();
                    return;
                }
            }
        });
    }

    const resultNavigate = function (result) {
        resetNavigation().then(() => {
            navigation("/pay/result/" + result)
        });
    }


    useEffect(() => {
        CreateOrder(tariffId, paymentMethodId, web, ton).then((data) => {


            if (ton) {
                const body = beginCell()
                    .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                    .storeStringTail(data.comment) // write our text comment
                    .endCell();
                tonConnectUI.sendTransaction({
                    validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                    messages: [{
                        address: data.address,
                        amount: toNano(data.amount).toString(),
                        payload: body.toBoc().toString("base64")
                    }]
                }).then((response) => {

                }).catch((error) => {
                    resultNavigate("method-failed");
                });
            }
            setPaymentData(data);
        }).catch(error => {
            resultNavigate("method-failed");
        });
        return () => {

        }
    }, [tariffId, paymentMethodId]);

    useEffect(() => {
        if (paymentData == null || orderStatus === "completed" || orderStatus === "failed" || orderStatus === "paymentFailed") {
            return;
        }
        const interval = setInterval(async () => {
            const status = await OrderStatus(paymentData.id);
            if (status.status !== orderStatus) {
                setOrderStatus(status.status);
                const order = await GetOrder(paymentData.id);
                if (status.status === "completed") {
                    resetNavigation().then(() => {
                        navigation("/simcards/" + order.simCard.iccid);
                    })
                } else if (status.status === "paymentFailed") {
                    resultNavigate("order-failed");
                } else if (status.status === "esimFailed") {
                    resultNavigate("esim-failed");
                } else {
                    setOrder(order);
                }
            } else {

                setOrder(order);
            }

        }, 1000);
        return () => clearInterval(interval);
    }, [paymentData, orderStatus, order]);

    return (<div className={"container page"}>
        {!paymentData ? (<>
            <LoadingSpinner/>
        </>) : ((order == null || order.status === "created")) ? ton ? (<div className={"info-page"}>
            <img src={ProgressLogo}/>
            <h2>{localization.GetString("paymant-progress-ton")}</h2>
            <p>{localization.GetString("paymant-progress-ton-text")}</p>
        </div>) : (<Payment paymentData={paymentData}/>) : (<div className={"info-page"}>
            <img src={ProgressLogo}/>
            {order.status === "paid" && (<>
                <h2>{localization.GetString("payment-success")}</h2>
                <p>{localization.GetString("payment-success-text")}</p>
            </>)}
        </div>)}
    </div>);

}

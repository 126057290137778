import React, {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DataStateContext} from "../context/dataState";
import {LocalizationContext} from "../common/Locaization";
import Header from "../components/Header";
import Payment from "../elements/Payment";
import PaymentMethods from "../elements/PaymentMethods";
import {CreateOrder, CreatePayment, GetPaymentMethods, GetTariff} from "../common/Api";
import BundleHeader from "../elements/BundleHeader";
import {AppStateContext} from "../context/appState";
import Profile from "./profile/profile";
import Pay from "./pay";
import {LoadingSpinner} from "../components/LoadingSpinner";

export default function BundleCheckout() {
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const localization = useContext(LocalizationContext);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const {id, tariffId} = useParams();
    const navigation = useNavigate();
    const [bundle, setBundle] = useState(null);
    const [tariff, setTariff] = useState(null);
    useEffect(() => {
        setBundle(dataState.bundles?.find(b => b.id == id));

    }, [id, dataState.bundles]);
    useEffect(() => {

        let tariff = dataState.tariffs?.find(b => b.id == tariffId);
        if (tariff) {
            setTariff(tariff);
            return;
        }
        GetTariff(tariffId).then(data => {
            setTariff(data);
        });

    }, [tariffId, dataState.tariffs]);

    useEffect(() => {
        if (paymentMethods) {
            return;
        }
        GetPaymentMethods().then(data => {
            setPaymentMethods(data);
        });
    }, []);

    return (
        <div className={"container page"}>
            <div className={"content"}>
                {bundle == null || tariff == null ? (<LoadingSpinner/>) : (<>
                    <div className={"tariff-details"}>
                        <BundleHeader bundle={bundle}/>
                        <div className={"row"}>
                            <div className={"title"}>
                                {localization.GetString("details_data")}
                            </div>
                            <div className={"value"}>
                                {tariff.dataSize} {localization.GetString("gb")}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"title"}>
                                {localization.GetString("details_validity")}
                            </div>
                            <div className={"value"}>
                                {tariff.days} {localization.GetString("days")}
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"title"}>
                                {localization.GetString("details_price")}
                            </div>
                            <div className={"value"}>
                                {tariff.price} $
                            </div>
                        </div>
                    </div>
                    <PaymentMethods paymentMethods={paymentMethods} onSelect={(paymentMethod) => {
                        navigation("/pay/" + tariffId + "/" + paymentMethod.id);
                    }} bundle={bundle}/></>)}
            </div>
        </div>
    );
}

import React, {useEffect} from "react";
import Payframe from "./payframe";
import config from "../config";
import TonPayment from "./TonPayment";

export default function Payment({paymentData}) {
    if (paymentData.type === "url") {
        if (paymentData.isTelegramUrl) {
            window.Telegram.WebApp.openTelegramLink(paymentData.url);
            return;
        }
        return (<Payframe url={paymentData.url}/>)
    }
    if (paymentData.type === "ton") {
        return (<TonPayment paymentData={paymentData}/>)
    }
    return (<></>);
}

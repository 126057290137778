import React from "react";

export const initialAppState = {
    categoryTab: 0,
    bundleSearchText: "",
    navigationActions: []
}

export function AppStateReducer(state, action) {

    switch (action.type) {
        case "setCategoryTab":
            return {
                ...state,
                categoryTab: action.payload
            };
        case "setBundleSearchText":
            return {
                ...state,
                bundleSearchText: action.payload
            };
        case "pushNavigationAction":
            return {
                ...state,
                navigationActions: [...state.navigationActions, action.payload]
            };
        case "popNavigationAction":

            let count = 1;
            if (action.payload?.count) {
                count = action.payload.count;
            }
            if (count > state.navigationActions.length) {
                count = state.navigationActions.length;
            }
            return {
                ...state,
                navigationActions: state.navigationActions.slice(0, state.navigationActions.length - count)
            };
        default:
            return state;
    }
}

export const AppStateContext = React.createContext(initialAppState);

import React, {useContext, useEffect} from "react";
import {Locales, useIsConnectionRestored, useTonAddress, useTonConnectUI, useTonWallet} from '@tonconnect/ui-react';
import config from "../config";
import {TonAuth} from "../common/Api";
import {DataStateContext} from "../context/dataState";


export default function TonConnector() {

    const wallet = useTonWallet();
    const userFriendlyAddress = useTonAddress();
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [tonConnectUI] = useTonConnectUI();
    useIsConnectionRestored()
    useEffect(()=>{
        tonConnectUI.connector.restoreConnection();
    },[])

    useEffect(() => {
        if (wallet != null && userFriendlyAddress != null) {
            TonAuth(wallet.account.walletStateInit, wallet.account.address, userFriendlyAddress).then((data) => {
                dispatchDataState({type: 'updateUserData', payload: data});
            });
        }
    }, [wallet, userFriendlyAddress]);

    return null;
}

import React from 'react';

const messages = {
    "countries": {
        values: {
            "en": "Countries", "ru": "Страны"
        }
    },
    "regions": {
        values: {
            "en": "Regions", "ru": "Регионы"
        }
    },
    "search_placeholder": {
        values: {
            "en": "Search a country or plan", "ru": "Поиск страны или тарифа"
        }
    },
    "no_results_found": {
        values: {
            "en": "Nothing found. Try changing your search parameters",
            "ru": "Ничего не найдено. Попробуйте изменить параметры поиска"
        }
    },
    "app_title": {
        values: {
            "en": "Mobile Market", "ru": "Mobile Market"
        }
    },
    "app_subtitle": {
        values: {
            "en": "bot", "ru": "bot"
        }
    },
    "data_plan": {
        values: {
            "en": "Internet", "ru": "Интернет"
        }
    },
    "category_countries": {
        values: {
            "en": "Countries", "ru": "Страны"
        }
    },
    "category_regions": {
        values: {
            "en": "Regions", "ru": "Регионы"
        }
    },
    "category_global": {
        values: {
            "en": "Global", "ru": "Глобальные"
        }
    },
    "popular_countries": {
        values: {
            "en": "Popular countries", "ru": "Популярные страны"
        }
    },
    "all_countries": {
        values: {
            "en": "All countries", "ru": "Все страны"
        }
    },
    "choose_a_data_plan": {
        values: {
            "en": "Choose a data plan", "ru": "Выберите тариф"
        }
    },
    "details_data": {
        values: {
            "en": "Data", "ru": "Данные"
        }
    },
    "details_validity": {
        values: {
            "en": "Validity", "ru": "Срок действия"
        }
    },
    "details_price": {
        values: {
            "en": "Price", "ru": "Цена"
        }
    },
    "gb": {
        values: {
            "en": "GB", "ru": "ГБ"
        }
    },
    "days": {
        values: {
            "en": "Days", "ru": "Дней"
        }
    },
    "payment_methods": {
        values: {
            "en": "Payment methods", "ru": "Способы оплаты"
        }
    },
    "go_to_checkout": {
        values: {
            "en": "Go to checkout", "ru": "Оплатить"
        }
    },
    "connect_ton": {
        values: {
            "en": "Connect TON", "ru": "Подключить TON"
        }
    },
    "available_countries": {
        values: {
            "en": "Available countries: ", "ru": "Доступные страны: "
        }
    },
    "payment-success": {
        values: {
            "en": "Payment successful", "ru": "Оплата прошла успешно"
        }
    },
    "payment-success-text": {
        values: {
            "ru": "Ваш платеж успешно обработан. Идет активация тарифа.",
            "en": "Your payment was successful. Activating tariff now."
        }
    },
    "payment-failed": {
        values: {
            "en": "Payment failed", "ru": "Ошибка оплаты"
        }
    },
    "payment-failed-text": {
        values: {
            "en": "Your payment was not successful. Please try again.", "ru": "Оплата не прошла. Попробуйте еще раз."
        }
    },
    "payment-unknown-status": {
        values: {
            "ru": "Что-то пошло не так",
            "en": "Something went wrong"
        }
    },
    "payment-unknown-text": {
        values: {
            "ru": "Пожалуйста повторите заказ. Если оплата была произведена обратитесь в поддержку.",
            "en": "Please try again. If payment was made contact support."
        }
    },
    "payment-method-failed": {
        values: {
            "ru": "Ошибка оплаты",
            "en": "Payment error"
        }
    },
    "payment-method-failed-text": {
        values: {
            "ru": "Платеж не прошел. Попробуйте повторить попытку или выберите другой способ оплаты.",
            "en": "Payment failed. Please try again or choose another payment method."

        }
    },
    "paymant-progress-ton": {
        values: {
            "ru": "Обработка транзакции",
            "en": "Transaction processing"
        }
    },
    "paymant-progress-ton-text": {
        values: {
            "ru": "Подтвердите транзакцию и дождитесь подтверждения",
            "en": "Confirm the transaction and wait for confirmation"
        }
    },
    "install": {values: {"en": "Install", "ru": "Установка"}},
    "how-to-install": {values: {"en": "How to install eSIM", "ru": "Как установить eSIM"}},
    "how-to-install-step1": {
        values: {
            "en": "If you want to install eSIM on this phone, send the QR code to another phone or computer. Then scan it with this phone. The code can only be scanned once!",
            "ru": "Если вы хотите установить eSIM на этот телефон, отправьте QR-код на другой телефон или компьютер. Затем отсканируйте его на этом телефоне. Код можно отсканировать только один раз!"
        }
    },
    "how-to-install-step2": {
        values: {
            "en": "Then follow the instructions in the phone settings. You need internet to install eSIM. Connect to Wi-Fi or use your main SIM card.",
            "ru": "Затем следуйте инструкциям в настройках телефона. Для установки eSIM вам нужен интернет. Подключитесь к Wi-Fi или используйте основную SIM-карту."
        }
    },
    "how-to-install-step3": {
        values: {
            "ru": "Для ручной установки воспользуйтесь следующими данными:",
            "en": "For manual installation use the following data:"
        }
    },
    "payment-error": {
        values: {
            "en": "Payment error. Please try again or choose another payment method.",
            "ru": "Ошибка оплаты. Попробуйте еще раз или выберите другой способ оплаты."
        }
    },
    "ton_payment_info_description": {
        values: {
            "ru": "Для оплаты вам нужено совершить платеж по указаным ниже реквизитам. Вы можете отсканировать QR код или воспользоваться кнопкой оплаты.",
            "en": "To pay, you need to make a payment using the details below. You can scan the QR code or use the payment button.",
        }
    },
    "ton_payment_info_address": {
        values: {
            "ru": "Адрес кошелька",
            "en": "Wallet address"
        }
    },
    "ton_payment_info_amount": {
        values: {
            "ru": "Сумма",
            "en": "Amount"
        }
    },
    "ton_payment_info_comment": {
        values: {
            "ru": "Комментарий",
            "en": "Comment"
        }
    },
    "ton_payment_info_button": {
        values: {
            "ru": "Оплатить",
            "en": "Pay"
        }
    },
    "account": {
        values: {
            "ru": "Аккаунт",
            "en": "Account"
        }
    },
    "my_account": {
        values: {
            "ru": "Мой аккаунт",
            "en": "My account"
        }
    },
    "profile": {
        values: {
            "ru": "Профиль",
            "en": "Profile"
        }
    },
    "settings": {
        values: {
            "ru": "Настройки",
            "en": "Settings"
        }
    },
    "my_esims": {
        values: {
            "ru": "Мои eSIM",
            "en": "My eSIMs"
        }
    },
    "my_esims_active": {
        values: {
            "ru": "Активные",
            "en": "Active"
        }
    },
    "my_esims_archived": {
        values: {
            "ru": "Архивные",
            "en": "Archived"
        }
    },


    "my_esims_remaining_days": {
        values: {
            "ru": "Осталось дней",
            "en": "Remaining days"
        }
    },
    "my_esims_date": {
        values: {
            "ru": "Дата покупки",
            "en": "Purchase date"
        }
    },
    "my_esims_activation_date": {
        values: {
            "ru": "Дата активации",
            "en": "Activation date"
        }
    },
    "my_esims_expiration_date": {
        values: {
            "ru": "Дата окончания",
            "en": "Expiration date"
        }
    },
    "my_esims_remaining_data": {
        values: {
            "ru": "Осталось данных",
            "en": "Remaining data"
        }
    },
    "my_esims_no_active": {
        values: {
            "ru": "Нет активных eSIM",
            "en": "No active eSIMs"
        }
    },
    "my_esims_no_archived": {
        values: {
            "ru": "Нет архивных eSIM",
            "en": "No archived eSIMs"
        }
    },
    "data_loading": {
        values: {
            "ru": "-",
            "en": "-"
        }
    },
    "orders": {
        values: {
            "ru": "Заказы",
            "en": "Orders"
        }
    },
    "language": {
        values: {
            "ru": "Язык",
            "en": "Language"
        }
    },
    "language_ru": {
        values: {
            "ru": "Русский",
            "en": "Russian"
        }
    },
    "language_en": {
        values: {
            "ru": "Английский",
            "en": "English"
        }
    },

    "theme": {
        values: {
            "ru": "Тема",
            "en": "Theme"
        }
    },
    "theme_light": {
        values: {
            "ru": "Светлая",
            "en": "Light"
        }
    },
    "theme_dark": {
        values: {
            "ru": "Темная",
            "en": "Dark"
        }
    },
    "theme_system": {
        values: {
            "ru": "Системная",
            "en": "System"
        }
    },
    "my_orders": {
        values: {
            "ru": "Мои заказы",
            "en": "My orders"
        }
    },
    "order_status": {
        values: {
            "ru": "Статус",
            "en": "Status"
        }
    },
    "order_status_created": {
        values: {
            "ru": "Создан",
            "en": "Created"
        }
    },
    "order_status_paid": {
        values: {
            "ru": "Оплачен",
            "en": "Paid"
        }
    },
    "order_status_paymentFailed": {
        values: {
            "ru": "Ошибка оплаты",
            "en": "Payment failed"
        }
    },
    "order_status_completed": {
        values: {
            "ru": "Завершен",
            "en": "Completed"
        }
    },
    "order_status_failed": {
        values: {
            "ru": "Ошибка",
            "en": "Failed"
        }
    },
    "order_status_expired": {
        values: {
            "ru": "Истек",
            "en": "Expired"
        }
    },
    "order_status_canceled": {
        values: {
            "ru": "Отменен",
            "en": "Canceled"
        }
    },
    "order_date": {
        values: {
            "ru": "Дата",
            "en": "Date"
        }
    },
    "order_payment_date": {
        values: {
            "ru": "Дата оплаты",
            "en": "Payment date"
        }
    },
    "order_payment_method": {
        values: {
            "ru": "Способ оплаты",
            "en": "Payment method"
        }
    },
    "get_invoice": {
        values: {
            "ru": "Счет",
            "en": "Invoice"
        }
    },
    profile_other: {
        values: {
            "ru": "Другое",
            "en": "Other"
        }
    },
    privacy_notes: {
        values: {
            "ru": "Политика конфиденциальности",
            "en": "Privacy notes"
        }
    },
    complaints_policy: {
        values: {
            "ru": "Политика жалоб",
            "en": "Complaints policy"
        }
    },
    terms_and_conditions: {
        values: {
            "ru": "Условия и положения",
            "en": "Terms and Conditions"
        }
    },
    contact_us: {
        values: {
            "ru": "Связаться с нами",
            "en": "Contact us"
        }
    },


}

export const localization = {
    language: null, SetLanguage: function (lang) {
        this.language = lang;
    }, GetLocalized: function (localizedMessage) {
        if (!this.language || localizedMessage?.values[this.language] == null) {
            return "";
        }
        return localizedMessage.values[this.language];
    },

    GetString: function (key, params = null) {

        let localized = this.GetLocalized(messages[key]);

        if (localized == null) {
            return key;
        }

        if (params != null) {
            for (let key in params) {
                localized = localized.replace("{" + key + "}", params[key]);
            }
            return localized;
        }
        return localized;
    }
}
export const LocalizationContext = React.createContext({});

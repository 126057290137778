import React from "react";
import countriesBg from "../assets/countries.svg";


export default function Bg({src}) {
    return null;
    /*if (src == null) {
        src = countriesBg;
    }

    return (<div className={"bg countries-bg"}
                 style={{
                     backgroundImage: `url(${src})`
                 }}
    ></div>);*/
}

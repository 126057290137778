import React, {useContext} from "react";
import {LocalizationContext} from "../common/Locaization";
import BundleHeader from "./BundleHeader";
import {AppStateContext} from "../context/appState";
import SimCardInfo from "../screens/profile/SimCardInfo";
import {StringDate} from "../common/DateHelper";
import {useNavigate} from "react-router-dom";

export default function SimCardListItem({simCard}) {
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const navigation = useNavigate();

    return (<div className={"data-list-item"} onClick={() => {
        navigation("/simcards/" + simCard.iccid)
    }}>
        {simCard.dataPackages.map((bundle) => (<>
            <BundleHeader bundle={bundle}/>
            <div className={"data-item-details"}>
                <div className={"row"}>
                    <div className={"label"}>{localization.GetString("my_esims_date")}</div>
                    <div
                        className={"value"}>{StringDate(bundle.createdAt)}</div>

                </div>
                <div className={"row"}>
                    <div className={"label"}>ICCID</div>
                    <div className={"value"}>
                        {simCard.iccid}
                    </div>
                </div>

                {(bundle.status !== "deactivated") && (
                    <div className={"row"}>
                        <div className={"label"}>{localization.GetString("my_esims_remaining_days")}</div>
                        <div
                            className={"value"}>{bundle.remainingDays ? bundle.remainingDays : localization.GetString("data_loading")}</div>

                    </div>)}
                {(bundle.status !== "deactivated") && (
                    <div className={"row"}>
                        <div className={"label"}>{localization.GetString("my_esims_remaining_data")}</div>
                        <div
                            className={"value"}>{bundle.remainingData ? bundle.remainingData + localization.GetString("gb") : localization.GetString("data_loading")}</div>

                    </div>)}
            </div>
        </>))}
    </div>)
}

import React, {useContext} from "react";
import {DataStateContext} from "../context/dataState";
import loadingIcon from "../assets/loading.gif";

export default function GlobalLoading() {
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    if (!dataState.loading) {
        return null;
    }
    return (
        <div style={{
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
        }}>
            <div>
                <img style={{width: "40px"}} src={loadingIcon}/>
            </div>
        </div>
    );
}

import React, {useContext, useEffect, useState} from "react";
import {LocalizationContext} from "../../common/Locaization";
import {GetOrders, GetSimCards} from "../../common/Api";
import Header from "../../components/Header";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import OrderListItem from "../../elements/OrderListItem";

export default function Orders() {

    const localization = useContext(LocalizationContext);
    const [orders, setOrders] = useState(null);
    useEffect(() => {

        GetOrders().then(data => {
            setOrders(data);
        });
    }, []);

    return (<div className={"container page"}>
        <div className={"content"}>
            <div className={"pagetitle"}>{localization.GetString("my_orders")}</div>

            {orders == null ? <LoadingSpinner/> : (
                <div className={"orders"}>
                    {orders.map(order => (
                        <OrderListItem order={order}/>))}
                </div>
            )}
        </div>
    </div>);


}

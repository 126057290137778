import React, {useCallback, useEffect, useReducer} from "react";
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import {DataStateReducer, DataStateContext} from "./context/dataState";
import {
    GetBundles, GetCountries, GetNotificationToken, GetOrder, GetPaymentMethods, GetSimCard, GetUserData, GetWebUserData
} from "./common/Api";

import {localization, LocalizationContext} from "./common/Locaization";
import {LoadingSpinner} from "./components/LoadingSpinner";
import useWebSocket from 'react-use-websocket';
import config from "./config";
import BackNavigationController from "./components/BackNavigationController";
import GlobalLoading from "./elements/GlobalLoading";
import Profile from "./screens/profile/profile";
import SimCards from "./screens/profile/simcards";
import {AppStateContext, AppStateReducer, initialAppState} from "./context/appState";
import Bundles from "./screens/bundles";
import SimCardInfo from "./screens/profile/SimCardInfo";
import AppNavigation from "./components/AppNavigation";
import TonConnector from "./elements/TonConnector";
import {TonConnectUIProvider} from "@tonconnect/ui-react";

function App() {
    const [dataState, dispatchDataState] = useReducer(DataStateReducer, {});
    const [appState, dispatchAppState] = useReducer(AppStateReducer, initialAppState);

    useEffect(() => {
        if (config.telegram) {
            window.Telegram.WebApp.MainButton.enable();
            window.Telegram.WebApp.expand();
        }
    }, []);
    useEffect(() => {
        let getUserFunction;
        if (config.website) {
            getUserFunction = GetWebUserData
        } else if (config.tonkeeper) {
            getUserFunction = async () => {
                return {
                    language: navigator.language === "ru-RU" ? "ru" : "en",
                    theme: "dark",
                }

            }
        } else {
            getUserFunction = GetUserData

        }

        getUserFunction().then(data => {
            dispatchDataState({type: 'updateUserData', payload: data});
            const countriesTask = GetCountries().then(data => {
                dispatchDataState({type: 'updateCountries', payload: data});

            });
            const bundlesTask = GetBundles().then(data => {
                dispatchDataState({type: 'updateBundles', payload: data});
            });
            if (config.telegram) {
                GetPaymentMethods().then(data => {
                    dispatchDataState({type: 'updatePaymentMethods', payload: data});
                });
            }
            Promise.all([countriesTask, bundlesTask]).then(() => {
                //    dispatchDataState({type: 'setLoading', payload: false});
            });

        });
    }, []);
    useEffect(() => {
        if (dataState.userData == null) {
            return;
        }
        if (dataState.userData.language) {
            localization.SetLanguage(dataState.userData.language);
        }
        if (dataState.userData.theme) {
            let theme;
            if (dataState.userData.theme === "system") {
                theme = window.Telegram.WebApp.colorScheme === "dark" ? "dark" : "light";
            } else {
                theme = dataState.userData.theme;
            }

            if (theme === "dark") {
                window.Telegram.WebApp.setHeaderColor("#334A62");
                window.Telegram.WebApp.setBackgroundColor("#334A62");
            } else {
                window.Telegram.WebApp.setHeaderColor("#EFEEF4");
                window.Telegram.WebApp.setBackgroundColor("#EFEEF4");
            }

            document.documentElement.setAttribute('data-theme', theme);
        }

    }, [dataState.userData]);

    const ActiveComponent = () => {
        if (appState.navigationActions.length == 0) {
            return null;
        }
        return appState.navigationActions[appState.navigationActions.length - 1]
    }

    return (<TonConnectUIProvider  manifestUrl="https://app.tonmobile.com/tonconnect-manifest.json">
        <AppStateContext.Provider value={[appState, dispatchAppState]}>
            <DataStateContext.Provider value={[dataState, dispatchDataState]}>
                <LocalizationContext.Provider value={localization}>
                    {config.tonkeeper && (<TonConnector/>)}
                    <AppNavigation/>
                </LocalizationContext.Provider>
                <GlobalLoading/>
            </DataStateContext.Provider>
        </AppStateContext.Provider>
    </TonConnectUIProvider>);
}

export default App;

export default {
    tonkeeper: true,
    imgUrl: "https://img.mobile.ag/",

    baseName: "/",
}


window.setTimeout(() => {
    window.Telegram =
        {
            WebApp: {
                openLink: (link) => {
                    window.open(link, "_blank");
                },
                openTelegramLink: (link) => {
                    window.open(link, "_blank");
                },
                showAlert: (text) => {
                    alert(text);
                },
                setHeaderColor: (color) => {

                },
                setBackgroundColor: (color) => {

                },
                expand: () => {

                },
                MainButton: {
                    enable: () => {

                    },
                    onClick: (arg) => {

                    },
                    offClick: () => {

                    },
                    show: () => {

                    },
                    hide: () => {

                    },

                },
                BackButton: {
                    onClick: (arg) => {

                    },
                    offClick: () => {

                    },
                    show: () => {

                    },
                    hide: () => {

                    }
                }


            }
        }
}, 0);

import React, {useContext, useEffect, useState} from "react";
import {GetSimCards} from "../../common/Api";
import Header from "../../components/Header";
import {LocalizationContext} from "../../common/Locaization";
import {DataStateContext} from "../../context/dataState";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import SimCardListItem from "../../elements/SimCardListItem";
import SimCardInfo from "./SimCardInfo";
import NotFound from "../../elements/NotFound";

export default function SimCards() {
    const localization = useContext(LocalizationContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [simCards, setSimCards] = useState(null);

    useEffect(() => {

        GetSimCards().then(data => {
            const simCards = {
                active: [], archived: []
            }
            data.forEach(simCard => {
                // if any dataPackage in dataPackages has status not equal to "deactivated" then add to active
                if (simCard.dataPackages.some(dataPackage => dataPackage.status !== "deactivated")) {
                    simCards.active.push(simCard);
                } else {
                    simCards.archived.push(simCard);
                }
            });
            setSimCards(simCards);
        });
    }, []);

    return (<div className={"container page"}>
        <div className={"content"}>
            <div className={"pagetitle"}>{localization.GetString("my_esims")}</div>

            {simCards == null ? <LoadingSpinner/> : (<Tabs>
                <TabList>
                    <Tab>{localization.GetString("my_esims_active")}</Tab>
                    <Tab>{localization.GetString("my_esims_archived")}</Tab>
                </TabList>
                <TabPanel>
                    {simCards.active.length > 0 ? simCards.active.map(simCard => (
                        <SimCardListItem simCard={simCard}/>)) : (
                        <NotFound text={localization.GetString("my_esims_no_active")}/>)}
                </TabPanel>
                <TabPanel>
                    {simCards.archived.length > 0 ? simCards.archived.map(simCard => (
                        <SimCardListItem simCard={simCard}/>)) : (
                        <NotFound text={localization.GetString("my_esims_no_archived")}/>)}
                </TabPanel>
            </Tabs>)
            }</div>
    </div>);
}

import BundleFlag from "./BundleFlag";
import React, {useContext} from "react";
import {LocalizationContext} from "../common/Locaization";
import {StringDate} from "../common/DateHelper";
import config from "../config";

export default function OrderListItem({order}) {
    const localization = useContext(LocalizationContext);

    return (
        <>
            <div className={"data-list-item"}>
                <div className={"order-header"}>
                    <BundleFlag
                        iconName={order?.iconName}/>
                    <div className={"order-title"}>{localization.GetLocalized(order.name)}</div>
                    <div className={"order-price"}>{order.price}$</div>
                </div>
                <div className={"data-item-details"}>
                    <div className={"row"}>
                        <div className={"label"}>{localization.GetString("order_status")}</div>
                        <div className={"value"}>
                            {localization.GetString("order_status_" + order.status)}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"label"}>{localization.GetString("order_date")}</div>
                        <div className={"value"}>
                            {StringDate(order.createdAt)}
                        </div>
                    </div>

                    {order.paidAt && (<div className={"row"}>
                        <div className={"label"}>{localization.GetString("order_payment_date")}</div>
                        <div className={"value"}>
                            {StringDate(order.paidAt)}
                        </div>
                    </div>)}
                </div>
                {order.invoiceAvailable && (<button className={"btn big-button"} onClick={() => {
                        window.Telegram.WebApp.openLink(config.invoiceUrl + "?orderId=" + order.id);
                    }}>{localization.GetString("get_invoice")}</button>
                )}
            </div>

        </>
    )

}
